import React, {useEffect} from "react";
import {StaffAccessLevel, UserData} from "../../../../api/staff/code";
import {useAppDispatch, useAppSelector} from "../../../../components/Hooks/redux";
import {getStaffMembersByAccessLevel} from "../../../../store/staffList/thunks/thunks";
import {clearStaffListStore} from "../../../../store/staffList/staffListSlice";
import {Modal} from "pulse-modal";

// eslint-disable-next-line no-shadow
export enum StaffListModalAccessLevel {
    DutyManager,
    SystemAdministrator,
    SuperAdministrator
}

export interface StaffListModalProps {
    onClose: () => void;
    isShown: boolean;
    accessLevel: StaffListModalAccessLevel;
}

export default function StaffListModal(props: StaffListModalProps) {
    const dispatch = useAppDispatch();
    const accessLevel = props.accessLevel;
    const shown = props.isShown;
    const modalTitle = `${staffAccessLevelToHuman(props.accessLevel)}s`;

    useEffect(() => {
        if (shown) {
            const baseLevel = getBaseAccessLevel(accessLevel);
            dispatch(getStaffMembersByAccessLevel(baseLevel));
        }

        return () => {
            dispatch(clearStaffListStore());
        };
    }, [shown, accessLevel, dispatch]);

    return (
        <Modal
            modalSize="md"
            title={modalTitle}
            bodyChildren={<StaffListContent accessLevel={props.accessLevel} />}
            onClose={props.onClose}
            isShown={props.isShown}
        />
    );
}

interface StaffListContentProps {
    accessLevel: StaffListModalAccessLevel;
}

function StaffListContent(props: StaffListContentProps) {
    const staffListStore = useAppSelector((state) => state.staffList);
    let staffList: UserData[] = [];

    if (staffListStore.data) {
        staffList = staffListStore.data;

        if (props.accessLevel === StaffListModalAccessLevel.SuperAdministrator) {
            staffList = staffList.filter((x) => x.superAdmin === true);
        }
    }

    return (
        <div>
            <ul>
                {staffList.map((x) => (
                    <li key={x.username}>
                        {x.firstName} {x.lastName}
                    </li>
                ))}
            </ul>
        </div>
    );
}

function getBaseAccessLevel(viewLevel: StaffListModalAccessLevel): StaffAccessLevel {
    switch (viewLevel) {
        case StaffListModalAccessLevel.DutyManager:
            return StaffAccessLevel.DutyManager;
        case StaffListModalAccessLevel.SystemAdministrator:
        case StaffListModalAccessLevel.SuperAdministrator:
            return StaffAccessLevel.SystemAdministrator;

        default:
            return StaffAccessLevel.SystemAdministrator;
    }
}

function staffAccessLevelToHuman(level: StaffListModalAccessLevel): string {
    switch (level) {
        case StaffListModalAccessLevel.DutyManager:
            return "Duty Manager";
        case StaffListModalAccessLevel.SystemAdministrator:
            return "System Administrator";
        case StaffListModalAccessLevel.SuperAdministrator:
            return "Super Administrator";
    }
}
